import { get } from '@/helpers/api'
import { computed, reactive, ref } from 'vue'
import { useRouter } from 'vue-router/composables'

class PageDefaultData<T> {
    public loading: boolean = false
    public itemsNotFound: boolean = false
    public modalFilterVisible: boolean = false
    public modalAddItemVisible: boolean = false
    public modalConfirmVisible: boolean = false
    public modalImportContacts = false
    public itemsList: T[] = []

    public pagination = {
        current_page: 1,
        per_page: 20,
        last_page: 0,
        total: 0,
    }
}

export function urlParamsToQueryObject(urlParams: URLSearchParams) {
    return Object.fromEntries(urlParams)
}

export function syncUrlParamsToModel(model: any) {
    const urlParams = new URLSearchParams(document.location.search)
    for (const [key, value] of urlParams) {
        if (key in model) {
            if (/^\d+$/.test(value)) {
                model[key] = Number(value)
            } else {
                model[key] = value
            }
        }
    }
    return urlParams
}

export function syncModelToUrlParams(model: any) {
    const urlParams = new URLSearchParams()
    for (const [key, value] of Object.entries(model)) {
        if (value !== null && value !== undefined && value !== '' && !(key === 'page' && value === 1)) {
            urlParams.set(key, `${value}`)
        }
    }
    return urlParams
}

interface ReactiveDependency {
    [key: string]: any
}

export function useMarketingPage<T>(){
    const router = useRouter()

    const pageData = reactive<PageDefaultData<T>>({
        ...new PageDefaultData() as PageDefaultData<T>,
    })

    const removableItem = ref<T | null>(null)

    const editableItem = ref<T | null>(null)

    const itemsOrSkeletonList = computed<T[] | any>(() => {
        return pageData.itemsList.length ? pageData.itemsList : pageData.pagination.per_page
    })

    const paginationItemsSkip = computed(() => {
        return (pageData.pagination.current_page - 1) * pageData.pagination.per_page
    })

    const paginationVisible = computed(() => {
        return pageData.pagination.total > pageData.itemsList.length
    })

    class PageDataProvider {
        public apiUrl: string = ''

        public routerUrl: string = ''

        public reactiveDepsFirstLoading: any[] = []

        public reactiveDepsNextLoading: any[] = []

        protected urlParams: URLSearchParams = new URLSearchParams()

        constructor(
            apiUrl: string,
            routerUrl: string,
            reactiveDepsFirstLoading: ReactiveDependency[],
            reactiveDepsNextLoading: ReactiveDependency[]
        ) {
            this.apiUrl = apiUrl
            this.routerUrl = routerUrl
            this.reactiveDepsFirstLoading = reactiveDepsFirstLoading
            this.reactiveDepsNextLoading = reactiveDepsNextLoading
        }

        public async fetch(loadingType: 'first-loading' | 'next-loading' = 'next-loading') {
            pageData.loading = true
            pageData.itemsNotFound = false

            try {
                if (loadingType === 'first-loading') {
                    this.updateReactiveDepsOnFirstLoading()

                }

                if (loadingType === 'next-loading') {
                    this.updateReactiveDepsOnNextLoading()
                }

                const response = await get(`${this.apiUrl}?${this.urlParams.toString()}`)

                pageData.itemsList = response.data.data.map(this.transformListItem)
                pageData.pagination.total = response.data.pagination.total || 0
                pageData.pagination.per_page = response.data.pagination.per_page
                pageData.pagination.current_page = response.data.pagination.current_page
                pageData.pagination.last_page = response.data.pagination.last_page
                pageData.itemsNotFound = pageData.itemsList.length ? false : true

                if (loadingType === 'next-loading') {
                    router.push({ path: this.routerUrl, query: urlParamsToQueryObject(this.urlParams)}, () => {})
                }

            } catch(error: any) {

            } finally {
                pageData.loading = false
            }
        }

        protected updateReactiveDepsOnFirstLoading(){
            this.reactiveDepsFirstLoading.forEach((data, index) => {
                if (index === 0) {
                    this.urlParams = syncUrlParamsToModel(data)
                }
                syncUrlParamsToModel(data)
            })
        }

        protected updateReactiveDepsOnNextLoading(){
            this.reactiveDepsNextLoading.forEach((data, index) => {
                if (index === 0) {
                    this.urlParams = syncModelToUrlParams(data)
                }
                syncModelToUrlParams(data)
            })
        }

        protected transformListItem(item: T) {
            return item
        }
    }

    return {
        itemsOrSkeletonList,
        paginationItemsSkip,
        paginationVisible,
        removableItem,
        editableItem,
        pageData,
        PageDataProvider
    }
}

import { del, get, post, put } from "@/helpers/api";

export const API_URL = "/marketing-bot/channels";

export const API_AUTOCOMPLETE_URL = "/marketing-bot/channels/lists";

export const API_COMPANIES_URL = "/cisco/campaigns";

export interface IPromoChannelItem {
    id: number,
    name: string,
    status: number,
    created_at: string,
    updated_at: string,
    source_id: number,
    source: {
        id: number,
        source: string,
        title: string
    }
}

export async function apiGetPromoChannelList(urlParams: string) {
    return await get(`${API_URL}?${urlParams}`);
}

export async function apiUpdatePromoChannel(id: number, data: any) {
    return await put(`${API_URL}/${id}`, data);
}

export async function apiCreatePromoChannel(data: any) {
    return await post(`${API_URL}`, data);
}

export async function apiDeletePromoChannel(id: number) {
    return await del(`${API_URL}/${id}`);
}

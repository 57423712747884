<template>
    <article
        class="app-file-uploader"
        :class="{
            'app-file-uploader--selected': value
        }"
        @click="clickHandler"
        @dragover.prevent="dragover"
        @drop.prevent="drop"
        @dragleave="dragleave"
    >
        <img
            :draggable="false"
            class="app-file-uploader__img"
            :src="imagePath"
            alt="excel"
        >
        <template v-if="!value">
            <i18n
                path="components.file-uploader.title"
                tag="p"
                class="app-file-uploader__title"
            >
                <a
                    class="app-file-uploader__link"
                    place="link"
                >
                    {{$t("components.file-uploader.link")}}
                </a>
            </i18n>
        </template>
        <div v-else class="app-file-uploader__file-info">
            <p class="app-file-uploader__name">
                {{model.name}}
            </p>
            <p class="app-file-uploader__size">
                {{size}}
            </p>
        </div>

        <input
            @change="model = $event"
            class="app-file-uploader__input"
            ref="input"
            type="file"
            :accept="accept"
        >
    </article>
</template>
<script>
export default {
    name: "FileUploader",
    emits: ["change"],
    props: {
        value: {
            type: File,
            default: null
        },
        accept: {
            type: String,
            default: ""
        },
        imagePath: {
            type: String,
            default: ""
        }
    },
    model: {
        event: "change",
        prop: "value"
    },
    data() {
        return {
            isDragging: false
        }
    },
    computed: {
        model: {
            get() {
                return {
                    name: this.value.name,
                    size: this.value.size
                }
            },
            set($val) {
                this.change($val?.target?.files?.[0] ?? $val.target.files[0])
            }
        },
        size() {
            return this.formatFileSize(this.value ? this.model.size : 0)
        }
    },
    methods: {
        clickHandler() {
            this.$refs.input.click()
        },
        change($val) {
            if(!$val) this.$emit("change", null)
            this.$emit("change", $val)
        },
        dragover() {
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        drop($val) {
            this.change($val?.dataTransfer?.files?.[0] ?? $val.dataTransfer.files[0])

            this.isDragging = false;
        },
        formatFileSize(size) {
            // TODO Вынести в глобальную видимость, или сделать отдельный composable
            if (size === 0) return '0 B';

            const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(size) / Math.log(1024));
            const formattedSize = parseFloat((size / Math.pow(1024, i)).toFixed(2));

            return `${formattedSize} ${units[i]}`;
        }
    },
    beforeDestroy() {
        this.change()
    }
}
</script>
<style lang="scss" src="./file-uploader.style.scss"></style>

import { del, get, post, put } from "@/helpers/api";

export const API_URL = "/marketing-bot/ads";

export const API_AUTOCOMPLETE_URL = "/marketing-bot/ads/lists";

export interface IPromoCompanyItem {
    id: number,
    name: string,
    product_id: number,
    channel_id: number,
    link: string,
    is_active: number,
    published_at: string,
    created_at: string,
    updated_at: string,
    interested: number,
    bot_link: string,
    product: {
        id: number,
        name: string,
        status: 1,
        created_at: string,
        updated_at: string
    },
    channel: {
        id: number,
        name: string,
        status: number,
        created_at: string,
        updated_at: string,
        source_id: number
    },
    campaign_id: number,
    campaign: {
        id: number,
        name: string
    }
}

export async function apiGetPromoCompaniesList(urlParams: string) {
    return await get(`${API_URL}?${urlParams}`);
}

export async function apiUpdatePromoCompany(id: number, data: any) {
    return await put(`${API_URL}/${id}`, data);
}

export async function apiCreatePromoCompany(data: any) {
    return await post(`${API_URL}`, data);
}

export async function apiDeletePromoCompany(id: number) {
    return await del(`${API_URL}/${id}`);
}

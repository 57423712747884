import { del, get, post, put } from "@/helpers/api"

export const API_URL = '/marketing-bot/products'

export const API_AUTOCOMPLETE_URL = '/marketing-bot/products/lists'

export interface IBankProductItem {
    id: number,
    created_at: string,
    updated_at: string,
    name: string,
    status: number,
}

export async function apiGetBankProductList(urlParams: string) {
    return await get(`${API_URL}?${urlParams}`)
}

export async function apiGetBankProductById(id: number) {
    return await get(`${API_URL}/${id}`)
}

export async function apiUpdateBankProduct(id: number, data: any) {
    return await put(`${API_URL}/${id}`, data)
}

export async function apiCreateBankProduct(data: any) {
    return await post(`${API_URL}`, data)
}

export async function apiDeleteBankProduct(id: number) {
    return await del(`${API_URL}/${id}`)
}
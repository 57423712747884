import {computed, getCurrentInstance} from "vue"
import {Vue} from "vue/types/vue";

export function useFilterCountText(model: any){
    const filterCountText = computed(() => {
        let count = 0
        for (const key in model) {
            if (key !== 'page' && model[key] !== '') {
                count++
            }
        }
        const app: Vue | undefined = getCurrentInstance()?.proxy;

        return count ? `${ app?.$i18n.t('common.filterWithCount', { count }) }` : `common.filter`
    })

    return {
        filterCountText
    }
}
